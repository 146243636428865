@import "bootstrap";
@import "../../node_modules/owl.carousel/src/scss/owl.carousel.scss";
@import "../../node_modules/fancybox/dist/scss/jquery.fancybox.scss";
button, button:visited, button:focus, button:active, button:hover, select, select:visited, select:focus, select:active, input:hover, input, input:visited, input:focus, input:active, input:hover, area, map {
	outline: 0 none !important; }
button:hover, a:hover {
	cursor: pointer; }
button {
	font-family: PT Sans;
	&:hover {
		color: #fff;
		background-color: #ef2533; } }
button.navbar-btn:hover {
	background-color: #fff; }
.section-title {
	font-family: Raleway;
	text-align: center;
	font-size: 48px;
	font-weight: 800;
	text-transform: uppercase;
	color: #505050; }
@import "modal";
@import "header";
@import "main-section";
@import "features";
@import "about";
@import "download";
@import "documents";
@import "teachers";
@import "working";
@import "feedback";
@import "questions";
@import "partners";
@import "footer";
@import "redline";
@import "media";



