.questions {
	padding-top: 70px;
	padding-bottom: 70px;
	background:  url(../img/questions-bg.png) center no-repeat;
	background-size: cover;
	&-title {
		text-align: left;
		margin-bottom: 65px;
		&::after {
			left: 0 !important; } }
	&-subtitle {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 400;
		color: #343434;
		margin-bottom: 25px; }
	&-form {
		&__label {
			margin-left: 10px;
			font-family: Roboto;
			font-size: 16px;
			font-weight: 400;
			color: #343434;
			sup {
				color: #ef2533;
				font-size: 18px; } }
		&__input {
			width: 85%;
			background-color: #ffffff;
			border: 1.0px solid #a4b1b7;
			border-radius: 11px;
			padding: 7px 15px;
			color: #343434;
			font-family: Roboto; }
		&__email {
			width: 93%; }
		&__btn {
			margin-top: 15px;
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: -0.01em;
			color: #ef2533;
			background-color: transparent;
			border: 2.0px solid #ef2533;
			border-radius: 24.5px;
			padding: 10px 23px;
			&:disabled {
				opacity: 0.78; } } }
	&-checkbox {
		display: none;
		&__label::before {
			content: "";
			width: 12px;
			height: 12px;
			border: 3px solid #fff;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: 0 0 2px #000;
			display: inline-block;
			margin-right: 10px; } } }
.questions-checkbox:checked + .questions-checkbox__label::before {
	background-color: #505050; }

/*Заствеляем фон*/
.light-bg {
  position: relative;
  z-index: 0;
  &::before {
   content: '';
   display: none;
   position: absolute;
   left: 0;
   top: 0;
   width: 100%;
   height: 100%;
   background-color: #f5f5f5;
   opacity: 0.5;
   z-index: -1; } }
