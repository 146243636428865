.footer {
	padding-top: 45px;
	padding-bottom: 30px;
	background-color: #c7d5da;
	&-item {
		text-align: center;
		margin-bottom: 20px; }
	&-conf {
		font-family: Roboto;
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;
		color: #000000; }
	&-call {
		display: flex;
		align-items: center;
		justify-content: center;
		img {
			margin-right: 3px; }
		a {
			font-family: Roboto;
			font-size: 20px;
			line-height: 16px;
			font-weight: 700;
			color: #000000;
			&:hover {
				text-decoration: none; } } }
	&-phone {
		font-family: Roboto;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
		color: #343434;
		margin-bottom: 15px;
		display: block; }
	&-seo {
		font-family: Roboto;
		font-size: 16px;
		line-height: 24px;
		font-weight: 400;
		color: #000000;
		span {
			font-weight: 700; }
		a {
			color: #000;
			text-decoration: underline; } } }
.open-conf:hover {
	cursor: pointer; }
