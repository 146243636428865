.features {
	padding-top: 65px;
	padding-bottom: 70px;
	&-item {
		text-align: center;
		&__img {
			height: 80px;
			margin-bottom: 20px; }
		&__title {
			font-family: Raleway;
			font-size: 20px;
			font-weight: 800;
			text-transform: uppercase;
			color: #505050;
			margin-bottom: 15px; }
		&__subtitle {
			font-family: Roboto;
			font-size: 16px;
			line-height: 27px;
			font-weight: 300;
			color: #343434; } } }

