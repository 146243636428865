.red-line {
	position: relative;
	&::after {
		content: '';
		width: 180px;
		height: 2px;
		display: block;
		background-color: #d10019;
		position: absolute;
		bottom: -25px;
		left: calc(50% - 90px); } }
@media (max-width: 991px) {
	.red-line::after {
		bottom: -15px; } }
