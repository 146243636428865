.overlay, .overlay-conf {
	position: fixed;
	display: none;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 3000;
	background-color: #15171f;
	background-color: rgba(21, 23, 31,0.6); }
.popup {
	z-index: 3500;
	position: fixed;
	left: 50%;
	width: 40%;
	top: 70px;
	transform: translateX(-50%);
	background-color: #fff;
	border-radius: 5.0px;
	padding: 40px 20px;
	text-align: left;
	&-close {
		position: absolute;
		font-size: 45px;
		right: 10px;
		top: -5px;
		font-weight: 300;
		cursor: pointer; }
	&-title {
		font-family: Raleway;
		font-size: 38px;
		font-weight: 800;
		text-transform: uppercase;
		color: #505050;
		margin-bottom: 50px; }
	&-form {
		&__label {
			margin-left: 10px;
			font-family: Roboto;
			font-size: 16px;
			font-weight: 400;
			color: #343434;
			sup {
				color: #ef2533;
				font-size: 18px; } }
		&__input {
			width: 100%;
			background-color: #ffffff;
			border: 1.0px solid #a4b1b7;
			border-radius: 11px;
			padding: 7px 15px;
			color: #343434;
			font-family: Roboto; } }
	&-checkbox {
		display: none;
		&__label::before {
			content: "";
			width: 12px;
			height: 12px;
			border: 3px solid #fff;
			background-color: #fff;
			border-radius: 50%;
			box-shadow: 0 0 2px #000;
			display: inline-block;
			margin-right: 10px; } }
	&-btn {
		margin-top: 15px;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: -0.01em;
		color: #ef2533;
		background-color: transparent;
		border: 2.0px solid #ef2533;
		border-radius: 24.5px;
		padding: 10px 23px;
		&:disabled {
			opacity: 0.78; } } }
.popup-checkbox:checked + .popup-checkbox__label::before {
	background-color: #505050; }
.popup .red-line::after {
	left: 0; }
.form-email-hide {
	display: none; }
.open-conf {
	text-decoration: underline; }
.popup-conf {
	text-align: center;
	width: 60%;
	height: 85%;
	overflow: auto;
	.red-line::after {
		left: calc(50% - 90px); } }
.conf-logo {
	margin-bottom: 30px; }
.conf-title {
	font-size: 26px;
	font-family: Raleway;
	font-weight: 800;
	text-transform: uppercase;
	color: #505050;
	margin-bottom: 40px; }

.form-success {
	margin-top: 15px;
	margin-bottom: 20px; }
.popup-form .hidden {
	display: none; }
.popup #recaptcha1 .grecaptcha-badge {
 }	// display: none !important
.error, .success {
	text-align: center;
	margin-bottom: 30px;
	font-family: Raleway;
	font-size: 24px;
	line-height: 48px;
	font-weight: 400; }
.error {
	color: red; }
.success {
	color: green; }



