.feedback {
	padding-top: 105px;
	padding-bottom: 80px;
	background-color: #f5f5f5;
	&-item {
		text-align: center;
		margin-top: 70px;
		&__video {
			margin-bottom: 20px;
			iframe {
				width: 100%;
				height: 200px; } }
		&__name {
			font-family: Roboto;
			font-size: 16px;
			font-weight: 400;
			color: #343434; } } }
