.teachers {
	padding-top: 105px;
	padding-bottom: 70px;
	background-color: #f5f5f5;
	&-main {
		text-align: center;
		margin-top: 80px;
		margin-bottom: 80px;
		&__title {
			margin-bottom: 15px;
			font-family: Raleway;
			font-size: 24px;
			line-height: 1.2;
			font-weight: 900;
			text-transform: uppercase;
			color: #505050; }
		&__descr {
			margin-bottom: 15px;
			font-family: Roboto;
			font-size: 18px;
			line-height: 27px;
			font-weight: 300;
			color: #343434; }
		&__name {
			font-family: Raleway;
			font-size: 24px;
			line-height: 1.2;
			font-weight: 900;
			text-transform: uppercase;
			color: #505050; }
		&__img {
			img {
				width: 100%; } } }
	&-item {
		text-align: center;
		&__img {
			margin-bottom: 30px;
			img {
				width: 100%; } }
		&__name {
			font-family: Raleway;
			font-size: 24px;
			line-height: 1.2;
			font-weight: 900;
			text-transform: uppercase;
			color: #505050;
			margin-bottom: 15px; }
		&__descr {
			font-family: Roboto;
			font-size: 16px;
			line-height: 27px;
			font-weight: 300;
			color: #343434; } } }
