@media (max-height: 600px) {
	.popup {
		height: 95%;
		overflow: scroll;
		top: 10px; } }
@media (max-width: 1199px) {
	.about, .documents, .teachers, .working, .feedback {
		padding-top: 129px; }
	.header-call__phone a {
		font-size: 15px; }
	.header-call__btn {
		padding: 5px 15px;
		font-size: 15px; }
	.main-text {
		width: 70%; }
	.main-consultation__text {
		text-transform: none; }
	.features-item__title {
		font-size: 18px;
		height: 81px; }
	.teachers-main__title, .teachers-main__name {
		font-size: 22px; }
	.teachers-main__descr {
		font-size: 16px; }
	.teachers-item__descr {
		font-size: 14px; }
	.working .row::after {
		top: 45%; }
	.working .col-2 {
	 padding: 0 10px; }
	.working-item__descr {
		font-size: 14px; }
	.partners-item img {
		width: 100%; }
	.footer-seo, .footer-conf {
		font-size: 15px; } }
@media (max-width: 991px) {
	.about, .documents, .teachers, .working, .feedback {
		padding-top: 102px; }
	.navbar-btn {
		display: inline-block; }
	.header-logo {
		text-align: center;
		img {
			width: 50%; } }
	.header-call {
		text-align: center;
		&__phone {
			justify-content: center; } }
	.main-text {
		width: 75%; }
	.main-text__big {
		font-size: 34px; }
	.main-text__small, .main-consultation__text {
		font-size: 18px; }
	.features-item__title {
		font-size: 16px;
		height: 96px; }
	.features-item__subtitle {
		font-size: 14px; }
	.section-title {
		font-size: 40px; }
	.about-descr__title {
		font-size: 20px; }
	.about-descr__text {
		font-size: 14px; }
	.about-img {
		padding-top: 100px; }
	.download-subtitle {
		font-size: 22px;
		margin-top: 15px;
		margin-bottom: 40px; }
	.download-form__input {
		width: 140%; }
	.documents {
		.col-lg-2 {
		 flex: 0 0 100%;
		 max-width: 100%; }
		.col-lg-5 {
			flex: 0 0 50%;
			max-width: 50%; } }
	.document-img .small-img {
		width: auto; }
	.teachers-main__title, .teachers-main__name {
		font-size: 20px; }
	.teachers-main__descr {
		font-size: 15px; }
	.teachers-item__name {
		font-size: 18px; }
	.teachers-main__img img {
		width: 130%; }
	.working-item__descr {
		font-size: 12px; }
	.questions-form__input {
		width: 100%; }
	.footer-seo, .footer-conf {
		font-size: 16px; }
	.popup {
		width: 60%;
		&-title {
			font-size: 30px; } } }
@media(max-width: 850px) {
	.main {
		background-position-x: -650px; }
	main.light-bg::before {
		display: block;
		opacity: 0.45; } }
@media (max-width: 767px) {
	.main {
		&__big {
			font-size: 30px;
			margin-bottom: 20px; }
		&-consultation {
			margin-top: 100px;
			&__text {
				font-size: 16px; }
			&__btn {
				font-size: 18px; } } }
	.about-img {
		padding-top: 30px; }
	.about-list {
		margin-bottom: 10px; }
	.download-text {
		text-align: justify; }
	.download-form__input {
		width: 160%; }
	.teachers-main__img {
		margin-top: 30px; }
	.teachers-main__img img {
		width: 100%; }
	.teachers-main {
		margin-top: 30px;
		margin-bottom: 30px; }
	.teachers-item__descr {
		margin-bottom: 15px; }
	.teachers-item__img .small-img {
		width: auto; }
	.working-item {
		margin-top: 35px; }
	.working .row::after {
		display: none; }
	.questions-title {
		margin-bottom: 35px; }
	.questions-checkbox__label {
		font-size: 14px; }
	.form-item {
		margin-bottom: 20px; }
	.footer-seo {
		margin-top: 25px; } }
@media (max-width: 750px) {
	.main {
		background-position-x: -725px; } }
@media (max-width: 680px) {
	.main {
		background-position-x: -750px; } }
@media (max-width: 650px) {
	.main {
		background-position-x: -775px; } }
@media (max-width: 600px) {
	.main {
		background-position-x: -825px; } }
@media (max-width: 575px) {
	.main {
		background-position-x: -850px;
		&-text {
			&__big {
				width: 80%;
				font-size: 26px; }
			&__small {
				font-size: 16px;
				width: 80%; } }
		&-consultation__text {
			display: inline-block; } }
	.features-item {
		margin-top: 20px; }
	.features-item__title {
		font-size: 15px;
		height: 66px; }
	.download-form__input {
		width: 100%; } }
@media (max-width: 550px) {
	.main {
		background-position-x: -875px; }
	.section-title {
		font-size: 32px; } }
@media (max-width: 500px) {
	.main {
		background-position-x: -925px;
		&-text {
			margin-top: 100px; } } }
@media (max-width: 425px) {
	.header-menu {
		padding-left: 10px;
		padding-right: 10px; }
	.main {
		background-position-x: -995px; }
	.features-item__title {
		font-size: 14px;
		height: 80px; }
	.features-item .small-title {
		height: auto; }
	.about-descr__title, .about-descr__text {
		text-align: center; }
	.about-btn {
		margin-bottom: 15px; }
	.download-form__input {
		width: 110%; }
	.documents {
		.col-lg-2 {
		 flex: 0 0 100%;
		 max-width: 100%; }
		.col-lg-5 {
			flex: 0 0 100%;
			max-width: 100%; } }
	.teachers-item__name {
		font-size: 16px; }
	.teachers-item__descr {
		font-size: 13px;
		line-height: 20px; }
	.questions {
		background-position-x: -1000px;
		text-align: center; }
	.questions-title {
		text-align: center;
		&::after {
			left: calc(50% - 90px) !important; } }
	.light-bg::before {
		display: block; }
	.popup {
		width: 100%;
		height: 100%;
		top: 0;
		overflow-y: auto; }
	.conf-title {
		font-size: 22px; }
	#reCaptcha-1 div, #reCaptcha-1 iframe {
		width: 100% !important; } }
@media (max-width: 380px) {
	.section-title {
		font-size: 28px; }
	.main {
		background-position-x: -1025px; }
	.main-text__big {
		font-size: 22px; }
	.main-text__small {
		font-size: 14px; }
	.about-descr__title {
		font-size: 18px; }
	.about-specialist, .about-list__item span {
		font-size: 14px; }
	.download-subtitle {
		font-size: 18px;
		line-height: 30px; }
	.footer-call a {
		font-size: 16px; }
	.popup-title {
		font-size: 28px; } }
@media (max-width: 340px) {
	.section-title {
		font-size: 26px; }
	.main {
		background-position-x: -1075px; }
	.main-text__big {
		font-size: 19px; }
	.teachers-item__img .small-img {
		width: 100%; }
	.working .col-4 {
		flex: 0 0 49.999%;
		max-width: 49.999%; }
	.feedback .col-6 {
		flex: 0 0 100%;
		max-width: 100%; }
	.footer .col-md-4 {
		flex: 0 0 100%;
		max-width: 100%; }
	.footer-conf {
		margin-bottom: 15px; }
	.footer-seo a {
		display: block; } }
