.working {
	padding-top: 105px;
	padding-bottom: 70px;
	&-item {
		margin-top: 100px;
		text-align: center;
		&__img {
			margin-bottom: 20px;
			height: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			img {
				background-color: #fff; } }
		&__descr {
			font-family: Roboto;
			font-size: 15px;
			font-weight: 400;
			color: #343434; } } }
.working .row {
	position: relative;
	&::after {
		content: "";
		background: url(../img/dots.png) center;
		height: 5px;
		width: 80%;
		position: absolute;
		top: 50%;
		left: 10%;
		z-index: -1; } }
