.documents {
	padding-top: 105px;
	padding-bottom: 80px;
	background-color: #fff;
	.col-lg-2, .col-lg-5 {
		padding: 0 10px; }
	.col-lg-2 {
	 flex: 0 0 19.998%;
	 max-width: 19.998%; }
	.col-lg-5 {
		flex: 0 0 39.998%;
		max-width: 39.998%; } }
.document {
	margin-top: 70px;
	text-align: center;
	&-img {
		display: inline-block;
		img {
			width: 100%;
			height: 310px; } }
	&-title {
		margin-top: 20px;
		font-family: Roboto;
		font-size: 15px;
		font-weight: 400;
		color: #343434;
		span {
			display: block; } } }
.fancybox-close {
	background-image: none;
	top: -3%;
	right: -40px;
	&::after {
		content: "\2716";
		color: #b4b4b4; }
	&:hover {
		text-decoration: none; } }
