.main {
	padding-top: 105px;
	background: url(../img/main-bg.png) center no-repeat;
	background-position-y: 105px;
	&-text {
		margin-top: 145px;
		width: 60%;
		&__big {
			font-family: Raleway;
			font-size: 40px;
			font-weight: 800;
			text-transform: uppercase;
			color: #343434;
			margin-bottom: 35px;
			span {
				color: #ef2533; } }
		&__small {
			font-family: Roboto;
			font-size: 22px;
			font-weight: 400;
			color: #343434; } }
	&-consultation {
		margin-top: 140px;
		padding-top: 28px;
		padding-bottom: 24px;
		background-color: rgba(255, 255, 255, 0.5);
		text-align: center;
		&__text {
			font-family: Roboto;
			font-size: 22px;
			font-weight: 400;
			color: #343434;
			text-transform: uppercase; }
		&__btn {
			margin-top: 10px;
			font-size: 22px;
			text-transform: uppercase;
			font-weight: 400;
			letter-spacing: -0.01em;
			color: #ffffff;
			background-color: #ef2533;
			border: none;
			border-radius: 24.5px;
			padding: 12px 70px; } } }
