.header {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: #fff;
	.col-lg-2, .col-lg-8 {
		padding: 0px 10px; }
	&-logo {
		img {
			width: 100%;
			height: auto; } }
	&-menu {
		text-align: center;
		&__list {
			padding-top: 25px;
			padding-left: 0; }
		&__item {
			list-style-type: none;
			display: inline;
			margin-right: 5px;
			a {
				font-family: Roboto;
				font-size: 13px;
				font-weight: 400;
				text-transform: uppercase;
				color: #000000;
				&:hover {
					text-decoration: none;
					border-bottom: 2px solid #ef2533; } } }
		&__vk a:hover {
				border-bottom: none; } }
	&-call {
		&__phone {
			display: flex;
			align-items: center;
			margin-bottom: 10px;
			img {
				margin-right: 3px; }
			a {
				font-family: Roboto;
				font-size: 18px;
				line-height: 16px;
				font-weight: 700;
				color: #000000;
				&:hover {
					text-decoration: none; } } }
		&__btn {
			font-size: 16.9px;
			font-weight: 400;
			letter-spacing: -0.01em;
			color: #fff;
			border: 2.0px solid #ef2533;
			border-radius: 17.3px;
			background-color: #ef2533;
			padding: 5px 20px;
			&:hover {
				color: #ef2533;
				background-color: #fff; } } } }
.navbar-btn {
	position: relative;
	padding: 9px 10px;
	background-color: transparent;
	background-image: none;
	border: 1px solid transparent;
	border-radius: 4px;
	display: none; }
.icon-bar {
	display: block;
	width: 22px;
	height: 2px;
	border-radius: 1px;
	background: #ef2533;
	margin-top: 2px; }
