.about {
	padding-top: 105px;
	padding-bottom: 80px;
	background-color: #f5f5f5;
	h2 {
		margin-bottom: 80px; }
	&-img {
		img {
			width: 100%;
			height: auto; } }
	&-descr {
		&__title {
			font-family: Raleway;
			font-size: 22px;
			line-height: 1.2;
			font-weight: 800;
			text-transform: uppercase;
			color: #505050;
			margin-bottom: 15px; }
		&__text {
			font-family: Raleway;
			font-size: 15px;
			line-height: 27px;
			font-weight: 300;
			color: #343434;
			margin-bottom: 10px; } }
	&-item {
		display: flex;
		align-items: center;
		margin-bottom: 10px;
		font-family: Roboto;
		font-size: 14px;
		font-weight: 700;
		color: #343434;
		span {
			text-transform: uppercase; }
		&__img {
			width: 30px;
			margin-right: 10px;
			text-align: center; } }
	&-specialist {
		margin-top: 30px;
		margin-bottom: 25px;
		font-family: Roboto;
		font-size: 16px;
		line-height: 27px;
		font-weight: 300;
		color: #343434; }
	&-list {
		padding-left: 20px;
		margin-right: 20px;
		margin-bottom: 30px;
		&__item {
			color: #d10019;
			span {
				font-family: Roboto;
				font-size: 16px;
				line-height: 27px;
				font-weight: 300;
				color: #343434;
				.bold {
					font-weight: 700; } } } }
	&-buttons {
		text-align: center; }
	&-btn {
		background-color: transparent;
		font-size: 18px;
		font-weight: 400;
		text-transform: uppercase;
		letter-spacing: -0.01em;
		color: #ef2533;
		padding: 10px 23px;
		border: 2.0px solid #ef2533;
		border-radius: 24.5px; } }

.about-position__title, .about-advantages__title {
	font-family: Roboto;
	font-size: 16px;
	line-height: 27px;
	font-weight: 700;
	color: #343434; }
.row-2 {
	display: inline-block; }
.price {
	color: #ef2533; }
.prof_standart {
	color: #ef2533;
	&:hover {
		color: #ef2533; } }
