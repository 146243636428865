.download {
	padding-top: 75px;
	padding-bottom: 80px;
	background: url(../img/download-bg.png) center no-repeat;
	background-size: cover;
	&-subtitle {
		margin-top: 20px;
		margin-bottom: 70px;
		font-family: Raleway;
		font-size: 24px;
		line-height: 48px;
		font-weight: 800;
		text-transform: uppercase;
		color: #505050;
		text-align: center; }
	&-form {
		text-align: center;
		&__label {
			margin-left: 10px;
			font-family: Roboto;
			font-size: 16px;
			font-weight: 400;
			color: #343434;
			sup {
				color: #ef2533;
				font-size: 18px; } }
		&__input {
			width: 120%;
			background-color: #ffffff;
			border: 1.0px solid #a4b1b7;
			border-radius: 11px;
			padding: 7px 15px;
			color: #343434;
			font-family: Roboto; }
		&__btn {
			margin-top: 15px;
			font-size: 18px;
			font-weight: 400;
			text-transform: uppercase;
			letter-spacing: -0.01em;
			color: #ef2533;
			background-color: transparent;
			border: 2.0px solid #ef2533;
			border-radius: 24.5px;
			padding: 10px 23px;
			&:disabled {
				opacity: 0.78; } } } }
.checkbox {
	display: none;
	&-label::before {
		content: "";
		width: 12px;
		height: 12px;
		border: 3px solid #fff;
		background-color: #fff;
		border-radius: 50%;
		box-shadow: 0 0 2px #000;
		display: inline-block;
		margin-right: 10px; } }
.form-item {
	text-align: left;
	margin-bottom: 30px; }
.checkbox:checked + .checkbox-label::before {
	background-color: #505050; }
#reCaptcha-2 {
	display: flex;
	justify-content: center; }
